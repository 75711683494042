<script lang="ts">
	import { cubicIn, cubicOut } from 'svelte/easing'
	import { scale } from 'svelte/transition'
	import { footerLinks } from '~/config'

	let isOpen = false
</script>

<svelte:body on:click={() => (isOpen = false)} />

<div class="flex items-center justify-between md:hidden">
	<div class="">
		<button
			aria-label="toggle menu"
			on:click|stopPropagation={() => (isOpen = !isOpen)}
			type="button"
			class="block rounded p-2 text-blue-300 hover:bg-blue-700 focus:bg-blue-300 focus:outline-none"
		>
			<svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
				{#if isOpen}
					<path
						fill-rule="evenodd"
						d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828
                  4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1
                  1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414
                  1.414l-4.828 4.829 4.828 4.828z"
					/>
				{/if}
				{#if !isOpen}
					<path
						fill-rule="evenodd"
						d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0
                  2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
					/>
				{/if}
			</svg>
		</button>
	</div>
</div>

{#if isOpen}
	<nav
		class="absolute inset-x-0 top-0 z-50 block p-2 text-lg"
		in:scale={{ duration: 100, start: 0.95, easing: cubicOut }}
		out:scale={{ duration: 75, start: 0.95, easing: cubicIn }}
	>
		<div
			class="origin-top-right transform rounded-lg bg-gray-900 shadow-md transition"
		>
			<div class="shadow-xs overflow-hidden rounded-lg">
				<div class="flex items-center justify-between px-5 pt-4">
					<div>
						<img
							class="h-8 w-auto rounded"
							src="/images/buildlab_beaker_transparent.png"
							alt="BuildLab"
						/>
					</div>
					<div class="-mr-2">
						<button
							aria-label="close menu"
							on:click={() => (isOpen = false)}
							type="button"
							class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
						>
							<svg
								class="h-6 w-6"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div class="px-2 pb-3 pt-2">
					{#each footerLinks as link}
						<a
							data-astro-prefetch="viewport"
							class="block rounded-md px-3 py-2 text-base font-bold transition duration-150 ease-in-out hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900 focus:outline-none"
							href={link.href}
						>
							{link.label}
						</a>
					{/each}
				</div>
			</div>
		</div>
	</nav>
{/if}
